<template>
    <v-container fluid>
      <v-row>
        <v-col class="pt-0">
          <span class="primary--text list-title">Guest Cards batch export {{ getCount }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5">
          <v-btn color="primary" @click="readClipboard('website')">
            Website
          </v-btn>
          <v-btn color="primary" class="ml-3" @click="readClipboard('rentCom')">
            Rent.com
          </v-btn>
          <v-btn color="primary" class="ml-3" @click="readClipboard('umass')">
            UMass
          </v-btn>
        </v-col>
        <v-col cols="12" md="3" class="pt-0" align="right">
          <v-select
            v-model="property"
            label="Property"
            :items="properties"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col>
          <v-btn icon color="primary" :disabled="saveEnabled" @click="saveItems()">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn icon color="primary" :disabled="saveEnabled" @click="clearItems()">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-card outlined>
            <v-card-text style="height: 100%;">
              <v-row class="px-3">
                <v-col cols="2" class="pa-0">
                  <span>Name</span>
                </v-col>
                <v-col cols="2" class="pa-0">
                  <span>Email</span>
                </v-col>
                <v-col cols="2" class="pa-0">
                  <span>Phone</span>
                </v-col>
                <v-col cols="1" class="pa-0">
                  <span>Move Date</span>
                </v-col>
                <v-col cols="3" class="pa-0">
                  <span>Comments</span>
                </v-col>
                <v-col cols="1" class="pa-0">
                  <span>Source</span>
                </v-col>
                <v-col cols="1" class="pa-0">&nbsp;</v-col>
              </v-row>
              <v-row class="px-3">
                <v-col>
                    <template v-for="(item, i) in items">
                      <v-row :key="i">
                        <v-col cols="2" class="pa-0 pt-2">
                          <span class="item-title">{{ item.firstName }} {{ item.lastName }}</span>
                        </v-col>
                        <v-col cols="2" class="pa-0 pt-2">
                          <span class="item-title">{{ item.email }}</span>
                        </v-col>
                        <v-col cols="2" class="pa-0 pt-2">
                          <span class="item-title">{{ item.phone }}</span>
                        </v-col>
                        <v-col cols="1" class="pa-0 pt-2">
                          <span class="item-title">{{ item.moveDate }}</span>
                        </v-col>
                        <v-col cols="3" class="pa-0 pt-2">
                          <span class="item-title">{{ item.comments }}</span>
                        </v-col>
                        <v-col cols="1" class="pa-0 pt-2">
                          <span class="item-title">{{ item.source }}</span>
                        </v-col>
                        <v-col cols="1" class="pa-0" align="right">
                          <v-btn icon color="primary" @click="deleteItem(item)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-divider v-if="i + 1 < items.length" :key="'d' + i"></v-divider>
                    </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GuestCards",

  data() {
    return {
      property: null,
      properties: [{ id: 0, name: "Property" }],
      items: [],
    };
  },

  computed: {
    ...mapGetters(["list", "user"]),
    saveEnabled() {
      if (!this.items || this.items.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    getCount() {
      if (this.items && this.items.length > 0) {
        return `(${this.items.length})`;
      } else {
        return "";
      }
    },
  },

  methods: {
    setDefaults() {
      if (this.properties) {
        this.property = this.properties[0].id;
      }
    },
    readClipboard(source) {
      navigator.clipboard.readText().then(
        (text) => {
          const notification = {
            show: true,
            result: true,
            message: "Copying from clipboard was successful",
          };

          this.$store.dispatch("showSnackbar", notification);

          if (source == "website") {
            this.processWebsite(text);
          } else if (source == "rentCom") {
            this.processRentCom(text);
          } else if (source == "umass") {
            this.processUMass(text);
          }
        },
        (error) => {
          console.error("response: ", error);
        }
      );
    },
    processWebsite(content) {
      let cards = this.getWebsiteItems(content.split(/\r?\n/));

      const id = this.getID(this.items);

      for (let i = 0; i < cards.length; i++) {
        const card = cards[i];

        card.id = id + i;

        if (card.source == undefined || card.source.length == 0) {
          card.source = "Unknown";
        }
      }

      if (cards && cards.length > 0) {
        this.addItems(cards);
      }
    },
    getWebsiteItems(content) {
      const items = [];

      for (let i = 0; i < content.length; i++) {
        const line = content[i];

        const ln = line.split("\t");

        if (ln.length == 8) {
          const item = {};

          item.id = 0;
          item.firstName = this.valueSingleCleanup(ln[0]);
          item.lastName = this.valueSingleCleanup(ln[1]);
          item.phone = ln[2];
          item.email = ln[3];
          item.moveDate = ln[4];
          item.comments = ln[5];
          item.source = ln[6];
          item.createdDate = ln[7];

          item.firstName = this.valueCleanUp(item.firstName);
          item.lastName = this.valueCleanUp(item.lastName);
          if (item.lastName == undefined || item.lastName.length == 0) {
            item.lastName = "No-Name";
          }
          item.email = this.valueCleanUp(item.email);
          item.phone = this.valueCleanUp(item.phone);

          if (item.moveDate) {
            const moveDate = new Date(item.moveDate.replace("-", "/"));
            item.moveDate = moveDate.toLocaleDateString("en-US");
          }

          item.comments = this.valueCleanUp(item.comments);

          if (item.createdDate) {
            const createdDate = new Date(item.createdDate);
            item.createdDate = createdDate.toLocaleDateString("en-US");
          }

          items.push(item);
        }
      }

      return items;
    },
    processRentCom(content) {
      let card = this.getRentComItem(content.split(/\r|\n|\t/));
      card.id = this.getID(this.items);
      card.source = "Rent.com";
      card.createdDate = new Date().toLocaleDateString("en-US");

      if (this.itemValid(card)) {
        this.addItems([card]);
      }
    },
    getRentComItem(content) {
      const item = {};

      let nameFound = false;
      let phoneFound = false;
      let emailFound = false;
      let moveInDateFound = false;
      let messageFound = false;

      for (let i = 0; i < content.length; i++) {
        const line = content[i];

        if (line.length > 0) {
          if (nameFound) {
            item.name = line.trim();
            const name = item.name.split(" ");

            if (name.length === 2) {
              item.firstName = this.valueCleanUp(name[0]);
              item.lastName = this.valueCleanUp(name[1]);
            } else if (name.length > 2) {
              item.firstName = this.valueCleanUp(name[0]);
              item.middleInitial = this.valueCleanUp(name[1]);
              item.lastName = this.valueCleanUp(name[2]);
            } else {
              item.lastName = "No Name";
            }

            nameFound = false;
            continue;
          } else if (phoneFound) {
            item.phone = this.valueCleanUp(line.trim());
            phoneFound = false;
            continue;
          } else if (emailFound) {
            item.email = this.valueCleanUp(line.trim());
            emailFound = false;
            continue;
          } else if (moveInDateFound) {
            const moveDate = new Date(line.trim());
            item.moveDate = moveDate.toLocaleDateString("en-US");
            moveInDateFound = false;
            continue;
          } else if (messageFound) {
            item.comments = this.valueCleanUp(line.trim());
            messageFound = false;
            continue;
          }

          if (line.startsWith("Name") && !item.name) {
            nameFound = true;
            continue;
          } else if (line.startsWith("Phone") && !item.phone) {
            phoneFound = true;
            continue;
          } else if (line.startsWith("Email") && !item.email) {
            emailFound = true;
            continue;
          } else if (line.includes("Move-in Date") && !item.moveDate) {
            moveInDateFound = true;
            continue;
          } else if (line.startsWith("Message") && !item.comments) {
            messageFound = true;
            continue;
          }
        }
      }

      return item;
    },
    getRentComItemOld(content) {
      const item = {};

      for (let i = 0; i < content.length; i++) {
        const line = content[i];
        console.log({ line });

        if (line.includes("Information for")) {
          item.name = line
            .substring(
              line.indexOf("Information for") + "Information for".length
            )
            .trim();
          if (item.name.length > 0) {
            const names = item.name.split(" ");

            if (names.length > 0) {
              item.firstName = names[0];
            }

            if (names.length == 2) {
              item.lastName = names[1];
            } else if (names.length == 3) {
              item.middleInitial = names[1];
              item.lastName = names[2];
            }
          }
        } else if (line.includes("Email:")) {
          item.email = line
            .substring(line.indexOf("Email:") + "Email:".length)
            .trim();
        } else if (line.includes("*Phone:")) {
          item.phone = line
            .substring(line.indexOf("*Phone:") + "*Phone:".length)
            .trim();
        } else if (line.includes("Move Date:")) {
          item.moveDate = line
            .substring(line.indexOf("Move Date:") + "Move Date:".length)
            .trim();
        } else if (line.includes("Comments:")) {
          item.comments = line
            .substring(line.indexOf("Comments:") + "Comments:".length)
            .trim();
        }
      }

      item.firstName = this.valueCleanUp(item.firstName);
      item.middleInitial = this.valueCleanUp(item.middleInitial);

      if (item.middleInitial != undefined && item.middleInitial.length > 1) {
        item.middleInitial = item.middleInitial.substring(0, 1);
      }

      item.lastName = this.valueCleanUp(item.lastName);
      if (item.lastName == undefined || item.lastName.length == 0) {
        item.lastName = "No-Name";
      }
      item.email = this.valueCleanUp(item.email);
      item.phone = this.valueCleanUp(item.phone);

      if (item.moveDate) {
        const moveDate = new Date(item.moveDate);
        item.moveDate = moveDate.toLocaleDateString("en-US");
      }

      item.comments = this.valueCleanUp(item.comments);

      return item;
    },
    processUMass(content) {
      let card = this.getUMassItem(content.split(/\r|\n/));
      card.id = this.getID(this.items);
      card.source = "UMass Amherst";
      card.createdDate = new Date().toLocaleDateString("en-US");

      if (this.itemValid(card)) {
        this.addItems([card]);
      }
    },
    getUMassItem(content) {
      const item = {};

      for (let i = 0; i < content.length; i++) {
        const line = content[i];

        if (line.includes("Name:")) {
          item.name = line
            .substring(line.indexOf("Name:") + "Name:".length)
            .trim();
          if (item.name.length > 0) {
            const names = item.name.split(" ");

            if (names.length > 0) {
              item.firstName = names[0];
            }

            if (names.length == 2) {
              item.lastName = names[1];
            } else if (names.length == 3) {
              item.middleInitial = names[1];
              item.lastName = names[2];
            }
          }
        } else if (line.includes("Email:")) {
          item.email = line
            .substring(line.indexOf("Email:") + "Email:".length)
            .trim();
        } else if (line.includes("Phone:")) {
          item.phone = line
            .substring(line.indexOf("Phone:") + "Phone:".length)
            .trim();
        } else if (line.includes("Move-in Availability:")) {
          const start =
            line.indexOf("Move-in Availability:") +
            "Move-in Availability:".length;
          const end = line.indexOf("(approximate move-in date)");

          item.moveDate = line.substring(start, end).trim();
        } else if (line.includes("Message:")) {
          item.comments = line
            .substring(line.indexOf("Message:") + "Message:".length)
            .trim();
        } else {
          item.comments += line.trim();
        }
      }

      item.firstName = this.valueCleanUp(item.firstName);
      item.middleInitial = this.valueCleanUp(item.middleInitial);

      if (item.middleInitial != undefined && item.middleInitial.length > 1) {
        item.middleInitial = item.middleInitial.substring(0, 1);
      }

      item.lastName = this.valueCleanUp(item.lastName);
      if (item.lastName == undefined || item.lastName.length == 0) {
        item.lastName = "No-Name";
      }
      item.email = this.valueCleanUp(item.email);
      item.phone = this.valueCleanUp(item.phone);

      if (item.moveDate && this.isValidDate(item.moveDate)) {
        const moveDate = new Date(item.moveDate);
        item.moveDate = moveDate.toLocaleDateString("en-US");
      } else {
        item.moveDate = null;
      }

      item.comments = this.valueCleanUp(item.comments);

      console.log("getUMassItem", item);
      return item;
    },
    getID(items) {
      let id = 1;

      if (items && items.length > 0) {
        id = Math.max.apply(
          Math,
          items.map(function (o) {
            return o.id;
          })
        );
        id++;
      }

      return id;
    },
    valueCleanUp(value) {
      if (value) {
        // eslint-disable-next-line no-control-regex
        value = value.replace(/[^\x00-\x7F]/g, "");
        value = value.replace(/,/g, ".");

        return value;
      } else {
        return value;
      }
    },
    valueSingleCleanup(value) {
      if (value.includes(" ")) {
        const ln = value.split(" ");
        return ln[0];
      } else {
        return value;
      }
    },
    itemValid(item) {
      if (item && item.name != undefined && item.name.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    addItems(items) {
      this.items.push(...items);
    },
    clearItems() {
      if (confirm("Are you sure you want to clear all records?")) {
        this.items = [];
      }
    },
    saveItems() {
      const property = this.properties.find((item) => item.id == this.property);
      if (!property) {
        return;
      }
      if (confirm(`Guest Cards will be exported for ${property.name}`)) {
        const header = this.getHeader();
        let content = this.getContent(header, ",");

        this.listToArr(this.items, property).forEach((item) => {
          content += this.getContent(item, ",");
        });

        const fileName = "Guest Cards.csv";
        this.download(fileName, content);
      }
    },
    getHeader() {
      var header = [
        "Property ID",
        "Property Name",
        "First Name",
        "Middle Initial",
        "Last Name",
        "Email",
        "Phone",
        "Interest Received",
        "Source",
        "Assigned To",
        "Beds",
        "Baths",
        "Additional Occupants",
        "Cats",
        "Dogs",
        "Other Pet",
        "Desired Move In",
        "Credit Score",
        "Monthly Income",
        "Max Rent",
        "Note",
      ];
      return header;
    },
    getContent(arr, delimiter) {
      if (arr == null || delimiter == null) return;

      let content = arr.join(delimiter) + "\r\n";
      return content;
    },
    listToArr(list, property) {
      var arr = [];

      for (var i = 0; i < list.length; i++) {
        const rec = list[i];

        arr.push([
          property.id,
          property.name,
          rec.firstName != null ? rec.firstName : "",
          rec.middleInitial != null ? rec.middleInitial : "",
          rec.lastName != null ? rec.lastName : "",
          rec.email != null ? rec.email : "",
          rec.phone != null ? rec.phone : "",
          rec.createdDate != null ? rec.createdDate : "",
          rec.source != null ? rec.source : "",
          property.email,
          "",
          "",
          "",
          "",
          "",
          "",
          rec.moveDate != null ? rec.moveDate : "",
          "",
          "",
          "",
          rec.comments != null ? rec.comments : "",
        ]);
      }

      return arr;
    },
    download(filename, text) {
      var el = document.createElement("a");
      el.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      el.setAttribute("download", filename);

      if (document.createEvent) {
        var event = document.createEvent("MouseEvents");
        event.initEvent("click", true, true);
        el.dispatchEvent(event);
      } else {
        el.click();
      }
    },
    deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.items = this.items.filter((itm) => itm.id !== item.id);
      }
    },
    isValidDate(d) {
      if (Object.prototype.toString.call(d) === "[object Date]") {
        if (isNaN(d.getTime())) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },

  mounted() {
    this.$store.dispatch("getList").then(() => {
      const list = [];
      this.list.map((item) => {
        if (item.property && item.property != undefined) {
          list.push(item.property);
        }
      });

      this.properties = list;

      this.setDefaults();
    });
  },
};
</script>

<style scoped>
.padding-top {
  padding-top: 30px;
}

.list-title {
  font-weight: 500;
  font-size: 1.1em;
}

.item-title {
  color: black;
  font-size: 1.1em;
}
</style>
<template>
    <v-container :class="{'padding-top': $vuetify.breakpoint.smAndDown }">
      <v-row>
        <v-col class="pt-0">
          <span class="primary--text list-title">{{ mode }} - {{ selectedList.title }}</span>
        </v-col>
        <v-col cols="12">
          <v-card outlined v-if="listTags && listTags.length">
            <v-btn
              v-show="showAdd"
              color="primary"
              dark
              small
              absolute
              top
              right
              fab
              @click="addItem()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-card-text style="height: 100%;">
              <v-row v-if="!$vuetify.breakpoint.smAndDown">
                  <v-col class="pa-0">
                    <v-container class="pa-0">
                      <v-row>
                        <v-col cols="10" class="pl-5">
                          Tag
                        </v-col>
                        <v-col cols="2" class="pa-0 pr-3">
                          <v-col class="px-0" align="center" justify="center">
                            Edit
                          </v-col>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
              </v-row>

              <template v-for="(item, i) in items">
                <v-row :key="i">
                    <v-col class="pa-0">
                      <v-container class="pa-0">
                        <v-row>
                          <v-col cols="10" class="pl-5 mt-1">
                            <span class="item-title">{{ item.tag }}</span>
                          </v-col>
                          <v-col cols="2" class="pa-0 pr-3" align="center" justify="center">
                            <v-btn icon @click="editItem(item)">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </v-col>                          
                        </v-row>
                      </v-container>
                    </v-col>
                </v-row>

                <v-divider v-if="i + 1 < listTags.length" :key="'d' + i"></v-divider>
              </template>
            </v-card-text>
          </v-card>
          <v-card v-else-if="selectedList && selectedList.id" flat>
            Click blue Add button to create first Tag
            <v-btn
              color="primary"
              dark
              small
              absolute
              top
              right
              fab
              @click="addItem()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card>
          <v-card v-else flat>
            Select a collection to see saved Tags
          </v-card>
        </v-col>
      </v-row>
      
      <v-dialog v-model="itemDialog" persistent :fullscreen="$vuetify.breakpoint.smAndDown" min-width="600px">
        <v-form ref="itemForm">
          <v-card>
            <v-container fluid class="pt-8 pr-5">
              <v-row>
                <v-col cols="12" sm="6" class="pt-0">
                  <v-text-field label="List Title" v-model="item.tag" :rules="inputRules" autofocus></v-text-field>

                  <v-card class="pa-2" outlined tile>
                    <v-textarea label="" v-model="item.content" auto-grow :rules="inputRules"></v-textarea>

                    <v-text-field label="Order" v-model="item.order" type="number"></v-text-field>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" class="pa-2">
                  <v-container class="pa-1">
                    <v-row class="pr-3">
                      <v-col cols="7" class="pa-0 pl-5">
                        <p class="v-toolbar__title primary--text pb-3">HTML Preview</p>
                      </v-col>
                      <v-col cols="2" class="pt-2">
                        <span class="primary--text" style="font-size: 0.65em; font-weight: bold;">
                          L: {{ getPreview(item.content) != null ? getPreview(item.content).length : '' }}
                        </span>
                      </v-col>
                      <v-col cols="1" class="pa-0">
                        <v-btn icon color="primary" :disabled="saveEnabled" @click="saveItem()">
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="1" class="pa-0">
                        <v-btn icon color="primary" :disabled="!item.id" @click="deleteItem(item)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="1" class="pa-0">
                        <v-btn icon color="primary" @click="close()">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-2">
                        <v-card class="pa-2 pt-7" outlined tile>
                          <div v-html="getPreview(item.content)"></div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-form>
      </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ListTag",

  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      item: {},
      originalItem: {},
      itemDialog: false,
      inputRules: [(v) => (!!v && v.length > 0) || "Field cannot be empty"],
    };
  },

  computed: {
    ...mapGetters(["selectedList", "listTags", "mode"]),
    showAdd() {
      return this.getParentID != "";
    },
    getParentID() {
      if (this.selectedList && this.selectedList.id) {
        return this.selectedList.id;
      } else {
        return "";
      }
    },
    valid() {
      return (
        this.item != null &&
        this.item.tag != undefined &&
        this.item.tag != "" &&
        this.item.tag.trim() != "" &&
        this.item.content != undefined &&
        this.item.content != "" &&
        this.item.content.trim() != ""
      );
    },
    dirty() {
      return JSON.stringify(this.item) !== JSON.stringify(this.originalItem);
    },
    saveEnabled() {
      if (!this.valid) {
        return true;
      } else if (this.dirty) {
        return false;
      } else {
        return true;
      }
    },
    tagsFormValid() {
      return (
        !this.tags.values.includes(undefined) && !this.tags.values.includes("")
      );
    },
  },

  methods: {
    addItem() {
      this.item = {
        tag: "",
        content: "",
        order: null,
      };
      this.originalItem = JSON.parse(JSON.stringify(this.item));

      this.itemDialog = true;
    },
    isHTML(text) {
      return /<\/?[a-z][\s\S]*>/i.test(text);
    },
    editItem(item) {
      this.item = JSON.parse(JSON.stringify(item));
      this.originalItem = JSON.parse(JSON.stringify(item));

      this.itemDialog = true;
    },
    saveItem() {
      if (!this.item.id) {
        const item = {
          listID: this.getParentID,
          tag: this.item.tag.trim(),
          content: this.item.content.trim(),
          order: this.item.order,
        };

        if (!item.order || item.order == undefined) {
          item.order = this.getNextOrder();
        }

        this.createItem(item);
        this.close();
      } else {
        const item = {
          id: this.item.id,
          listID: this.item.listID,
          tag: this.item.tag.trim(),
          content: this.item.content.trim(),
          order: this.item.order,
        };

        this.updateItem(item);
      }
    },
    createItem(item) {
      this.$store.dispatch("createListTag", item).then(
        (response) => {
          this.item = response.item;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          const notification = {
            show: true,
            result: false,
            message: "Error creating item",
          };

          this.$store.dispatch("showSnackbar", notification);
          console.error("response: ", error);
        }
      );
    },
    updateItem(item) {
      this.$store.dispatch("updateListTag", item).then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          console.error("response: ", error);
        }
      );
    },
    deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.$store.dispatch("deleteListTag", item).then(
          () => {
            const index = this.listTags.indexOf(item);
            this.listTags.splice(index, 1);

            this.itemDialog = false;
          },
          (error) => {
            console.error("response: ", error);
          }
        );
      }
    },
    close() {
      this.itemDialog = false;
    },
    getNextOrder() {
      if (this.listTags && this.listTags.length > 0) {
        return parseInt(this.listTags[this.listTags.length - 1].order) + 1;
      } else {
        return 1;
      }
    },
    getPreview(text) {
      if (text) {
        if (!this.isHTML(text)) {
          return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
        } else {
          return text;
        }
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.padding-top {
  padding-top: 30px;
}

.list-title {
  font-weight: 500;
  font-size: 1.1em;
}

.item-title {
  color: black;
  font-size: 1.1em;
}
</style>
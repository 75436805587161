export default {
  snackbar: {
    show: false,
    color: "success",
    timeout: 4000,
    message: ""
  },
  authReady: false,
  user: null,
  settings: {
    listId: null
  },
  loginDialog: false,
  loginLoading: false,
  list: null,
  listIndex: null,
  selectedList: null,
  mode: null,
  listItems: null,
  listTags: null
};

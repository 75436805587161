<template>
    <v-container :class="{'padding-top': $vuetify.breakpoint.smAndDown }">
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <span class="primary--text list-title">{{ mode }} - {{ selectedList.title }}</span>
        </v-col>
        <v-col cols="12" sm="6"  class="py-0">
          <v-text-field label="Search" v-model="search" prepend-icon="mdi-magnify" autofocus></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-card outlined v-if="itemsFiltered && itemsFiltered.length">
            <v-btn
              v-show="showAdd"
              color="primary"
              dark
              small
              absolute
              top
              right
              fab
              @click="addItem()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-card-text style="height: 100%;">
              <v-row v-if="!$vuetify.breakpoint.smAndDown">
                  <v-col class="pa-0">
                    <v-container class="pa-0">
                      <v-row>
                        <v-col cols="7" class="pl-5">
                          Title
                        </v-col>
                        <v-col cols="5" class="pa-0 pr-3">
                          <v-row>
                            <v-col class="px-0" align="center" justify="center">
                              plain
                            </v-col>
                            <v-col class="px-0" align="center" justify="center">
                              html
                            </v-col>
                            <v-col class="px-0" align="center" justify="center">
                              Edit
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
              </v-row>

              <template v-for="(item, i) in itemsFiltered">
                <v-row :key="i">
                    <v-col class="pa-0">
                      <v-container class="pa-0">
                        <v-row>
                          <v-col cols="7" class="pl-5 mt-1">
                            <span class="item-title">{{ item.title }}</span>
                          </v-col>
                          <v-col cols="5" class="pa-0 pr-3">
                            <v-row>
                              <v-col class="px-0" align="center" justify="center">
                                <v-btn icon @click="copyItem(item, false)">
                                  <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                              </v-col>
                              <v-col class="px-0" align="center" justify="center">
                                <v-btn icon :disabled="!isHTML(item.content)" @click="copyItem(item, true)">
                                  <v-icon>mdi-code-braces</v-icon>
                                </v-btn>
                              </v-col>
                              <v-col class="px-0" align="center" justify="center">
                                <v-btn icon @click="editItem(item)">
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>                          
                        </v-row>
                      </v-container>
                    </v-col>
                </v-row>

                <v-divider v-if="i + 1 < itemsFiltered.length" :key="'d' + i"></v-divider>
              </template>
            </v-card-text>
          </v-card>
          <v-card v-else-if="selectedList && selectedList.id" flat>
            Click blue Add button to create first Note
            <v-btn
              color="primary"
              dark
              small
              absolute
              top
              right
              fab
              @click="addItem()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card>
          <v-card v-else flat>
            Select a collection to see saved Notes
          </v-card>
        </v-col>
      </v-row>
      
      <v-dialog v-model="itemDialog" persistent :fullscreen="$vuetify.breakpoint.smAndDown" min-width="600px">
        <v-form ref="itemForm">
          <v-card>
            <v-container fluid class="pt-8 pr-5">
              <v-row>
                <v-col cols="12" sm="6" class="pt-0 pb-5">
                  <v-text-field label="List Title" v-model="item.title" :rules="inputRules" autofocus></v-text-field>

                  <v-card class="pa-2" outlined tile>
                    <v-textarea label="" v-model="item.content" auto-grow @input="inputChanged()" :rules="inputRules"></v-textarea>

                    <v-text-field label="Order" v-model="item.order" type="number"></v-text-field>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" class="pa-2 pt-0">
                  <v-container class="pa-1">
                    <v-row class="pr-3">
                      <v-col cols="7" class="pa-0 pl-5">
                        <p class="v-toolbar__title primary--text pb-3">HTML Preview</p>
                      </v-col>
                      <v-col cols="2" class="pt-2">
                        <span class="primary--text" style="font-size: 0.65em; font-weight: bold;">
                          L: {{ preview != null ? preview.length : '' }}
                        </span>
                      </v-col>
                      <v-col cols="1" class="pa-0" align="center" justify="center">
                        <v-btn icon color="primary" :disabled="saveEnabled" @click="saveItem()">
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="1" class="pa-0" align="center" justify="center">
                        <v-btn icon color="primary" :disabled="!item.id" @click="deleteItem(item)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="1" class="pa-0" align="center" justify="center">
                        <v-btn icon color="primary" @click="close()">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-2">
                        <v-card class="pa-2 pt-7" outlined tile>
                          <div v-html="preview"></div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="userTags.dialog" persistent :fullscreen="$vuetify.breakpoint.smAndDown" max-width="400px">
          <v-card>
              <v-card-title class="card-title">
                <v-container>
                  <v-row>
                    <v-col>
                      <span class="primary--text text--lighten-1" :class="{'padding-title': $vuetify.breakpoint.mdAndUp }">Provide values for template</span>
                    </v-col>

                    <v-col cols="1" class="pa-0" align="center" justify="center">
                      <v-btn icon color="primary" @click="showTagsDialog(false)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-title>

              <v-form ref="userTagsForm">
                <v-card-text>
                    <v-container 
                      v-for="(item, i) in userTags.list"
                      :key="i"
                      class="py-0">
                        <v-row>
                            <v-col class="py-0">
                              <v-text-field :label="userTags.list[i]" v-model="userTags.values[i]" :rules="inputRules"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    
                </v-card-text>

                <v-card-actions :class="{ 'padding-action-small': $vuetify.breakpoint.smAndDown, 'padding-action': $vuetify.breakpoint.mdAndUp }">
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary darken-1" text @click="clearTags()">Clear</v-btn>
                    <v-btn color="primary darken-1" text :disabled="!userTagsFormValid" @click="copyWithTags()">Apply & Copy</v-btn>
                </v-card-actions>
              </v-form>
          </v-card>
      </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ListItem",

  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      item: {},
      originalItem: {},
      itemDialog: false,
      preview: null,
      userTags: {
        list: [],
        values: [],
        dialog: false,
        text: "",
        html: false,
      },
      systemTags: {
        list: [],
      },
      inputRules: [(v) => (!!v && v.length > 0) || "Field cannot be empty"],
      search: "",
    };
  },

  computed: {
    ...mapGetters(["selectedList", "listTags", "mode"]),
    itemsFiltered() {
      const list = this.items.filter((itm) =>
        itm.title.toLowerCase().includes(this.search.toLowerCase())
      );

      return list;
    },
    showAdd() {
      return this.getParentID != "";
    },
    getParentID() {
      if (this.selectedList && this.selectedList.id) {
        return this.selectedList.id;
      } else {
        return "";
      }
    },
    valid() {
      return (
        this.item != null &&
        this.item.title != undefined &&
        this.item.title != "" &&
        this.item.title.trim() != "" &&
        this.item.content != undefined &&
        this.item.content != "" &&
        this.item.content.trim() != ""
      );
    },
    dirty() {
      return JSON.stringify(this.item) !== JSON.stringify(this.originalItem);
    },
    saveEnabled() {
      if (!this.valid) {
        return true;
      } else if (this.dirty) {
        return false;
      } else {
        return true;
      }
    },
    userTagsFormValid() {
      return (
        !this.userTags.values.includes(undefined) &&
        !this.userTags.values.includes("")
      );
    },
  },

  methods: {
    addItem() {
      this.item = {
        title: "",
        content: "",
        order: null,
      };
      this.originalItem = JSON.parse(JSON.stringify(this.item));

      this.itemDialog = true;
      this.refreshPreview();
    },
    copyItem(item, html) {
      this.userTags.list = this.findUserTags(item.content);
      this.systemTags.list = this.findSystemTags(item.content);

      if (this.userTags.list.length > 0) {
        this.userTags.text = item.content;
        this.userTags.html = html;
        if (this.userTags.values == null) {
          this.userTags.values = new Array(this.userTags.list.length);
        }
        this.showTagsDialog(true);
      } else {
        this.copyToClipboard(item.content, html);
      }
    },
    isHTML(text) {
      return /<\/?[a-z][\s\S]*>/i.test(text);
    },
    findUserTags(text) {
      if (text.includes("##")) {
        var result = text.match(/##(.*?)##/g).map(function (val) {
          return val.replace(/##/g, "");
        });

        return result;
      } else {
        return [];
      }
    },
    findSystemTags(text) {
      if (text.includes("#!")) {
        var result = text.match(/#!(.*?)!#/g).map(function (val) {
          return val.replace(/#!/g, "").replace(/!#/g, "");
        });

        return result;
      } else {
        return [];
      }
    },
    clearTags() {
      this.$refs.userTagsForm.reset();
    },
    copyWithTags() {
      this.userTags.text = this.replaceUserTags(this.userTags.text);
      this.copyToClipboard(this.userTags.text, this.userTags.html);
      this.showTagsDialog(false);
    },
    showTagsDialog(show) {
      this.userTags.dialog = show;
    },
    copyToClipboard(text, html) {
      text = this.replaceSystemTags(text);

      if (!html) {
        text = text.replace(/(<([^>]+)>)/gi, "");
      }

      navigator.clipboard.writeText(text).then(
        () => {
          const notification = {
            show: true,
            result: true,
            message: "Copying to clipboard was successful",
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          console.error("response: ", error);
        }
      );
    },
    replaceUserTags(text) {
      for (let i = 0; i < this.userTags.list.length; i++) {
        let tag = this.userTags.list[i];

        let value = this.userTags.values[i];

        if (value == undefined || value == "") {
          value = tag.toUpperCase();
        }

        tag = "##" + tag + "##";
        text = text.replace(tag, value);
      }

      return text;
    },
    replaceSystemTags(text) {
      for (let i = 0; i < this.systemTags.list.length; i++) {
        let systemTag = this.systemTags.list[i];

        const listTag = this.listTags.find(({ tag }) => tag === systemTag);

        systemTag = "#!" + systemTag + "!#";

        if (systemTag && listTag != undefined && listTag.content != undefined) {
          text = text.replace(systemTag, listTag.content);
        } else {
          text = text.replace(systemTag, "");
        }
      }

      return text;
    },
    editItem(item) {
      this.item = JSON.parse(JSON.stringify(item));
      this.originalItem = JSON.parse(JSON.stringify(item));

      this.itemDialog = true;
      this.refreshPreview();
    },
    saveItem() {
      if (!this.item.id) {
        const item = {
          listID: this.getParentID,
          title: this.item.title.trim(),
          content: this.item.content.trim(),
          order: this.item.order,
        };

        if (!item.order || item.order == undefined) {
          item.order = this.getNextOrder();
        }

        this.createItem(item);
        this.close();
      } else {
        const item = {
          id: this.item.id,
          listID: this.item.listID,
          title: this.item.title.trim(),
          content: this.item.content.trim(),
          order: this.item.order,
        };

        this.updateItem(item);
      }
    },
    createItem(item) {
      this.$store.dispatch("createListItem", item).then(
        (response) => {
          this.item = response.item;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          const notification = {
            show: true,
            result: false,
            message: "Error creating item",
          };

          this.$store.dispatch("showSnackbar", notification);
          console.error("response: ", error);
        }
      );
    },
    updateItem(item) {
      this.$store.dispatch("updateListItem", item).then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          console.error("response: ", error);
        }
      );
    },
    deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.$store.dispatch("deleteListItem", item).then(
          () => {
            const index = this.itemsFiltered.indexOf(item);
            this.itemsFiltered.splice(index, 1);

            this.itemDialog = false;
          },
          (error) => {
            console.error("response: ", error);
          }
        );
      }
    },
    close() {
      this.itemDialog = false;
    },
    getNextOrder() {
      if (this.items && this.items.length > 0) {
        return parseInt(this.items[this.items.length - 1].order) + 1;
      } else {
        return 1;
      }
    },
    inputChanged() {
      this.refreshPreview();
    },
    refreshPreview() {
      this.preview = this.getPreview(this.item);
    },
    getPreview(item) {
      if (!item || item.content == undefined) {
        return "";
      }

      let content = item.content;

      this.systemTags.list = this.findSystemTags(content);
      content = this.replaceSystemTags(content);

      this.userTags.list = this.findUserTags(content);
      content = this.replaceUserTags(content);

      if (content) {
        return this.replaceNewLineWithBr(content);
      } else {
        return "";
      }
    },
    replaceNewLineWithBr(text) {
      if (!this.isHTML(text)) {
        return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
      } else {
        return text;
      }
    },
  },
};
</script>

<style scoped>
.padding-top {
  padding-top: 30px;
}

.list-title {
  font-weight: 500;
  font-size: 1.1em;
}

.item-title {
  color: black;
  font-size: 1.1em;
}
</style>
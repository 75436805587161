export default {
  SHOW_SNACKBAR(state, payload) {
    state.snackbar.show = payload.show;

    if (payload.result) {
      state.snackbar.color = "success";
      state.snackbar.timeout = 4000;
      state.snackbar.message = payload.message;
    } else {
      state.snackbar.color = "error";
      state.snackbar.timeout = 5000;
      state.snackbar.message = payload.message;
    }
  },
  CHANGE_AUTH_READY(state) {
    state.authReady = true;
  },

  OPEN_LOGIN_DIALOG(state, open) {
    state.loginDialog = open;
  },
  LOGIN_LOADING(state, loading) {
    state.loginLoading = loading;
  },

  UPDATE_USER(state, user) {
    state.user = user;
  },

  SET_LIST(state, item) {
    state.settings.listId = item
  },
  GET_LIST(state, docs) {
    state.list = [];

    docs.forEach(doc => {
      state.list.push({
        ...doc.data(),
        id: doc.id
      });
    });
  },
  ADD_LIST(state, item) {
    if (item) {
      state.list.push(item)
    }
  },
  UPDATE_ITEM(state, item) {
    let foundItem = state.list.find(({ id }) => id === item.id);
    const index = state.list.indexOf(foundItem);
    state.list.splice(index, 1);

    state.list.push(item)
  },
  SET_SELECTED_LIST(state, item) {
    state.selectedList = item
  },
  SET_SINGLE_LIST(state, doc) {
    const item = doc.data()
    item.id = doc.id

    state.selectedList = item
  },
  SET_LIST_INDEX(state, item) {
    state.listIndex = item
  },
  SET_MODE(state, item) {
    state.mode = item
  },
  GET_LIST_ITEMS(state, docs) {
    state.listItems = [];

    docs.forEach(doc => {
      state.listItems.push({
        ...doc.data(),
        id: doc.id
      });
    });
  },
  ADD_LIST_ITEM(state, item) {
    if (item) {
      state.listItems.push(item)
    }
  },
  UPDATE_LIST_ITEM(state, item) {
    let foundItem = state.listItems.find(({ id }) => id === item.id);
    const index = state.listItems.indexOf(foundItem);
    state.listItems.splice(index, 1);

    state.listItems.push(item)
  },
  GET_LIST_TAGS(state, docs) {
    state.listTags = [];

    docs.forEach(doc => {
      state.listTags.push({
        ...doc.data(),
        id: doc.id
      });
    });
  },
  ADD_LIST_TAG(state, item) {
    if (item) {
      state.listTags.push(item)
    }
  },
  UPDATE_LIST_TAG(state, item) {
    let foundItem = state.listTags.find(({ id }) => id === item.id);
    const index = state.listTags.indexOf(foundItem);
    state.listTags.splice(index, 1);

    state.listTags.push(item)
  }
};

<template>
  <nav >
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" top :color="snackbar.color">
      <span>{{ snackbar.message }}</span>
      <v-btn color="white" dark text @click="closeSnackbar">Close</v-btn>
    </v-snackbar>

    <v-app-bar app flat dark color="primary" height="50">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>

      <v-toolbar-title>
        Web Notes
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text @click="openNotes()">Notes</v-btn>
        <v-btn text @click="openTags()">Tags</v-btn>
        <v-btn text @click="openGuestCards()">Guest Cards</v-btn>
        <v-btn v-if="!user" text @click="login()" class="hidden-sm-and-down">Login</v-btn>
        <v-btn v-else text @click="logoff()" class="hidden-sm-and-down">Logoff</v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" temporary width="320px">
      <v-layout row>
        <v-flex xs12>
          <v-card flat>
            <v-card-title>
              <v-btn fab small icon @click="drawer = false">
                <v-icon>mdi-chevron-left-circle-outline</v-icon>
              </v-btn>
            </v-card-title>
            <v-list class="pt-0">
              <ListSide v-on:hideDrawer="drawer=false" />
            </v-list>
          </v-card>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import ListSide from "@/components/ListSide";

import { mapGetters } from "vuex";
import router from "@/router";

export default {
  name: "NavBar",

  components: {
    ListSide
  },

  data() {
    return {
      drawer: false
    };
  },

  computed: {
    ...mapGetters(["snackbar", "user"])
  },

  methods: {
    login() {
      this.$store.dispatch("openLoginDialog", true);
    },
    logoff() {
      this.$store.dispatch("logoff");
      router.push({ name: "Home" }).catch(() => {});
    },
    databaseCleanup() {
      this.$store.dispatch("saveSettings", {
        key: "list",
        value: null
      });
    },
    openNotes() {
      this.$store.dispatch("setMode", "Notes");
      router.push({ name: "Notes" }).catch(() => {});
    },
    openTags() {
      this.$store.dispatch("setMode", "Tags");
      router.push({ name: "Tags" }).catch(() => {});
    },
    openGuestCards() {
      router.push({ name: "GuestCards" }).catch(() => {});
    },
    closeSnackbar() {
      this.$store.dispatch("closeSnackbar");
    }
  }
};
</script>

<style scoped>
.shadow {
  text-shadow: rgb(0, 0, 0) 1px 1px 5px;
}
</style>
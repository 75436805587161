<template>
  <v-container>
    <v-row>
      <v-col>Application requires registration. Login with credentials provided by application administator.</v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-dialog v-model="loginDialog" persistent :fullscreen="$vuetify.breakpoint.smAndDown" max-width="400px">
            <v-card>
                <v-card-title class="card-title">
                    <span class="headline primary--text text--lighten-1" :class="{'padding-title': $vuetify.breakpoint.mdAndUp }">Login</span>
                </v-card-title>

                <v-form ref="form">
                  <v-card-text>
                      <v-container>
                          <v-row>
                              <v-col cols="12" :class="{'padding-input': $vuetify.breakpoint.smAndDown }">
                                <v-text-field label="Email" v-model="email" prepend-icon="mdi-email" autofocus>
                                  <v-icon slot="prepend" color="primary lighten-1">mdi-phone</v-icon>
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" :class="{'padding-input': $vuetify.breakpoint.smAndDown }">
                                <v-text-field v-model="password"
                                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                  :type="showPassword ? 'text' : 'password'"
                                  label="Password"
                                  @click:append="showPassword = !showPassword">
                                  <v-icon slot="prepend" color="primary lighten-1">mdi-lock</v-icon>
                                </v-text-field>
                              </v-col>
                          </v-row>
                      </v-container>
                      
                  </v-card-text>

                  <v-card-actions :class="{ 'padding-action-small': $vuetify.breakpoint.smAndDown, 'padding-action': $vuetify.breakpoint.mdAndUp }">
                      <div class="flex-grow-1"></div>
                      <v-btn color="primary darken-1" text dark @click="clear()">Clear</v-btn>
                      <v-btn color="primary darken-1" text dark @click="login()" :loading="loginLoading">Login</v-btn>
                  </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

export default {
  data() {
    return {
      email: "",
      password: "",
      showPassword: false
    };
  },

  computed: {
    ...mapGetters(["loginDialog", "loginLoading", "user"])
  },

  methods: {
    login() {
      if (this.$refs.form.validate()) {
        const form = {
          email: this.email,
          password: this.password
        };

        this.$store.dispatch("login", form).then(
          response => {
            const notification = {
              show: true,
              result: true,
              message: response
            };

            this.$store.dispatch("showSnackbar", notification);

            this.resetForm();
            this.$router.push({ name: "Notes" }).catch(() => {});
          },
          error => {
            console.error("response: ", error);
          }
        );
      }
    },
    clear() {
      this.resetForm();
    },
    resetForm() {
      this.$refs.form.reset();
    }
  },

  mounted() {
    if (this.user) {
      router.push({ name: "Notes" }).catch(() => {});
    }
  }
};
</script>

import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/Home.vue";
import Notes from "@/views/Notes.vue";
import Tags from "@/views/Tags.vue";
import GuestCards from "@/views/GuestCards.vue"
import store from "@/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/notes",
      name: "Notes",
      component: Notes,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/tags",
      name: "Tags",
      component: Tags,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/guest-cards",
      name: "GuestCards",
      component: GuestCards,
      meta: {
        requiresAuth: true
      }
    },

  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.user) {
      next({
        path: "/",
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

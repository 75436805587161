import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import store from "@/store";

const firebaseConfig = {
  apiKey: "AIzaSyATV80quodY88Dal8DeGWsDBhWci_Q8D18",
  authDomain: "web-notes-01.firebaseapp.com",
  databaseURL: "https://web-notes-01.firebaseio.com",
  projectId: "web-notes-01",
  storageBucket: "web-notes-01.appspot.com",
  messagingSenderId: "322940039587",
  appId: "1:322940039587:web:4ada905bb19f2ccaf52a16"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
var storage = firebase.storage();

auth.onAuthStateChanged(user => {
  if (user) {
    user.getIdTokenResult().then(idTokenResult => {
      user.admin = idTokenResult.claims.admin;
    });

    store.dispatch("updateUser", user);
  } else {
    store.dispatch("updateUser", null);
  }

  store.dispatch("changeAuthReady");
});

export { db, auth, functions, storage };

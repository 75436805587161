<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col cols="4" v-if="!$vuetify.breakpoint.smAndDown && !singleCollection">
                <List />
            </v-col>
            <v-col :cols="listTagsCols">
                <ListTags :items="listTags" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import List from "@/components/List";
import ListTags from "@/components/ListTags";
import { mapGetters } from "vuex";

export default {
  name: "Tags",

  components: {
    List,
    ListTags
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters(["user", "list", "listTags"]),
    singleCollection() {
      if (this.list && this.list.length == 1) {
        return true;
      } else {
        return false;
      }
    },
    listTagsCols() {
      if (this.$vuetify.breakpoint.smAndDown || this.singleCollection) {
        return 12;
      } else {
        return 8;
      }
    }
  },

  methods: {},

  mounted() {
    this.$store.dispatch("getList");
  }
};
</script>
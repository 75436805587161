<template>
  <v-app>
    <NavBar />
    <v-content>
      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </v-content>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",

  components: {
    NavBar,
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    ...mapActions(["storeInit"]),
  },

  created: function () {
    this.storeInit();
  },
};
</script>

<style>
/* Transitions  */
.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

.row {
  margin-top: 0px;
  margin-right: -12px;
  margin-left: -12px;
  margin-bottom: 0px;
}
</style>

import { db, auth } from "@/services/fb";
import idb from "@/services/indexedDB";
import router from "@/router";

export default {
  // eslint-disable-next-line no-unused-vars
  storeInit({ dispatch }) {
    dispatch("getSettings")
    dispatch("setMode", "")
  },

  async getSettings({ commit }) {
    try {
      const list = await idb.get("settings", "list");
      commit("SET_LIST", list);
    } catch (error) {
      // eslint-disable-next-line
      console.error("Error getting settings: " + error);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async saveSettings({ commit }, payload) {
    const key = payload.key;
    const value = payload.value;

    try {
      await idb.save("settings", { key: key, value: value, });
    } catch (error) {
      // eslint-disable-next-line
      console.error("Error saving language: " + error);
    }
  },

  showSnackbar({ commit }, payload) {
    const show = payload.show;
    const result = payload.result;
    const message = payload.message;

    commit("SHOW_SNACKBAR", {
      show: show,
      result: result,
      message: message
    });
  },
  closeSnackbar({ commit }) {
    commit("SHOW_SNACKBAR", {
      show: false
    });
  },

  changeAuthReady({ commit }) {
    commit("CHANGE_AUTH_READY");
  },

  openLoginDialog({ commit, state }, open) {
    if (!state.user) {
      commit("OPEN_LOGIN_DIALOG", open);
    }
  },
  login({ commit }, form) {
    commit("LOGIN_LOADING", true);

    return new Promise((resolve, reject) => {
      auth
        .signInWithEmailAndPassword(form.email, form.password)
        .then(() => {
          commit("OPEN_LOGIN_DIALOG", false);
          commit("LOGIN_LOADING", false);
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Login successful"
          });

          resolve("Login successful");
        })
        .catch(err => {
          commit("LOGIN_LOADING", false);
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error login. " + err.message
          });

          console.error("Error: Login user: " + err.message);
          reject("Error login. " + err.message);
        });
    });
  },
  logoff({ commit }) {
    return new Promise((resolve, reject) => {
      auth
        .signOut()
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Logoff successful"
          });

          resolve("Logoff successful");
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error logoff. " + err.message
          });

          console.error("Error: Logoff user: " + err.message);
          reject("Error logoff. " + err.message);
        });
    });
  },

  updateUser({ commit }, user) {
    commit("UPDATE_USER", user);
  },

  getList({ commit, dispatch, getters }) {
    const user = getters.user

    if (!user) {
      return
    }

    return new Promise((resolve, reject) => {
      db.collection("list")
        .where("users", "array-contains", user.email)
        .get()
        .then(snapshot => {
          if (snapshot && snapshot.docs) {
            commit("GET_LIST", snapshot.docs);

            if ((!getters.selectedList || getters.selectedList.id == undefined) && (getters.settings.listId != undefined)) {
              dispatch("loadSavedList")
            } else if (snapshot.docs.length == 1) {
              commit("SET_SINGLE_LIST", snapshot.docs[0])

              dispatch("getListItems", { listID: snapshot.docs[0].id });
              dispatch("getListTags", { listID: snapshot.docs[0].id });
            }
          } else {
            console.log("No list found");
          }
          resolve({ message: "List found", list: getters.list });
        })
        .catch(err => {
          console.error("Error: Getting list: " + err.message);
          reject("Error getting list. " + err.message);
        });
    });
  },
  createList({ commit, getters }, payload) {
    const item = {
      title: payload.title,
      users: [getters.user.email],
      order: payload.order
    }

    return new Promise((resolve, reject) => {
      db.collection("list")
        .add(item)
        .then(doc => {
          item.id = doc.id;

          commit("ADD_LIST", item)

          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item created"
          });

          resolve({ message: "Item created", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error creating item"
          });

          console.error("Error: Creating Item: " + err.message);
          reject("Error creating item. " + err.message);
        });
    });
  },
  updateList({ commit }, payload) {
    const item = {
      id: payload.id,
      title: payload.title,
      order: payload.order
    }

    return new Promise((resolve, reject) => {
      db.collection("list")
        .doc(item.id)
        .update({
          title: item.title,
          order: item.order
        })
        .then(() => {
          commit("UPDATE_ITEM", item)

          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item updated"
          });

          resolve({ message: "Item updated", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error updating item"
          });

          console.error("Error: Updating Item: " + err.message);
          reject("Error updating item. " + err.message);
        });
    });
  },
  deleteList({ commit }, payload) {
    const id = payload.id

    return new Promise((resolve, reject) => {
      db.collection('list-items')
        .where('listID', '==', id)
        .get()
        .then((snapshot) => {
          var batch = db.batch();

          snapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });

          batch.commit();
        }).then(() => {
          commit("GET_LIST_ITEMS", []);

          db.collection('list-tags')
            .where('listID', '==', id)
            .get()
            .then((snapshot) => {
              var batch = db.batch();

              snapshot.forEach((doc) => {
                batch.delete(doc.ref);
              });

              batch.commit();
            }).then(() => {
              commit("GET_LIST_TAGS", []);

              db.collection("list")
                .doc(id)
                .delete()
                .then(() => {
                  commit("SET_SELECTED_LIST", null)

                  commit("SHOW_SNACKBAR", {
                    show: true,
                    result: true,
                    message: "Item deleted"
                  });

                  resolve("Item deleted");
                })
                .catch(err => {
                  commit("SHOW_SNACKBAR", {
                    show: true,
                    result: false,
                    message: "Error deleting item"
                  });

                  console.error("Error: Deleting Item: " + err.message);
                  reject("Error deleting item. " + err.message);
                });
            });
        });
    });
  },
  loadSavedList({ commit, dispatch, getters }) {
    const listId = getters.settings.listId
    const list = getters.list.find(({ id }) => id === listId);

    if (list) {
      const index = getters.list.indexOf(list);
      commit("SET_LIST_INDEX", index)

      dispatch("setSelectedList", list);
      dispatch("getListItems", { listID: list.id });
      dispatch("getListTags", { listID: list.id });
    }
  },
  setSelectedList({ commit }, payload) {
    commit("SET_SELECTED_LIST", payload);
  },
  setMode({ commit }, payload) {
    if (payload == '') {
      commit("SET_MODE", router.currentRoute.name)
    } else {
      commit("SET_MODE", payload)
    }
  },
  getListItems({ commit }, payload) {
    db.collection("list-items")
      .where("listID", "==", payload.listID)
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs) {
          commit("GET_LIST_ITEMS", snapshot.docs);
        } else {
          console.log("No list items found");
        }
      })
      .catch(err => {
        console.error("Error: Getting list items: " + err.message);
      });
  },
  createListItem({ commit }, payload) {
    const item = {
      listID: payload.listID,
      title: payload.title,
      content: payload.content,
      order: payload.order
    }

    return new Promise((resolve, reject) => {
      db.collection("list-items")
        .add(item)
        .then(doc => {
          item.id = doc.id;

          commit("ADD_LIST_ITEM", item)

          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item created"
          });

          resolve({ message: "Item created", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error creating item"
          });

          console.error("Error: Creating Item: " + err.message);
          reject("Error creating item. " + err.message);
        });
    });
  },
  updateListItem({ commit }, payload) {
    const item = {
      id: payload.id,
      listID: payload.listID,
      title: payload.title,
      content: payload.content,
      order: payload.order
    }

    return new Promise((resolve, reject) => {
      db.collection("list-items")
        .doc(item.id)
        .update({
          title: item.title,
          content: item.content,
          order: item.order
        })
        .then(() => {
          commit("UPDATE_LIST_ITEM", item)

          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item updated"
          });

          resolve({ message: "Item updated", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error updating item"
          });

          console.error("Error: Updating Item: " + err.message);
          reject("Error updating item. " + err.message);
        });
    });
  },
  deleteListItem({ commit }, payload) {
    return new Promise((resolve, reject) => {
      db.collection("list-items")
        .doc(payload.id)
        .delete()
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item deleted"
          });

          resolve("Item deleted");
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error deleting item"
          });

          console.error("Error: Deleting Item: " + err.message);
          reject("Error deleting item. " + err.message);
        });
    });
  },
  getListTags({ commit }, payload) {
    db.collection("list-tags")
      .where("listID", "==", payload.listID)
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.docs) {
          commit("GET_LIST_TAGS", snapshot.docs);
        } else {
          console.log("No list tags found");
        }
      })
      .catch(err => {
        console.error("Error: Getting list tags: " + err.message);
      });
  },
  createListTag({ commit }, payload) {
    const item = {
      listID: payload.listID,
      tag: payload.tag,
      content: payload.content,
      order: payload.order
    }

    return new Promise((resolve, reject) => {
      db.collection("list-tags")
        .add(item)
        .then(doc => {
          item.id = doc.id;

          commit("ADD_LIST_TAG", item)

          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item created"
          });

          resolve({ message: "Item created", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error creating item"
          });

          console.error("Error: Creating Item: " + err.message);
          reject("Error creating item. " + err.message);
        });
    });
  },
  updateListTag({ commit }, payload) {
    const item = {
      id: payload.id,
      listID: payload.listID,
      tag: payload.tag,
      content: payload.content,
      order: payload.order
    }

    return new Promise((resolve, reject) => {
      db.collection("list-tags")
        .doc(item.id)
        .update({
          tag: item.tag,
          content: item.content,
          order: item.order
        })
        .then(() => {
          commit("UPDATE_LIST_TAG", item)

          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item updated"
          });

          resolve({ message: "Item updated", item: item });
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error updating item"
          });

          console.error("Error: Updating Item: " + err.message);
          reject("Error updating item. " + err.message);
        });
    });
  },
  deleteListTag({ commit }, payload) {
    return new Promise((resolve, reject) => {
      db.collection("list-tags")
        .doc(payload.id)
        .delete()
        .then(() => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: true,
            message: "Item deleted"
          });

          resolve("Item deleted");
        })
        .catch(err => {
          commit("SHOW_SNACKBAR", {
            show: true,
            result: false,
            message: "Error deleting item"
          });

          console.error("Error: Deleting Item: " + err.message);
          reject("Error deleting item. " + err.message);
        });
    });
  }
};

<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col cols="4" v-if="!$vuetify.breakpoint.smAndDown && !singleCollection">
                <List />
            </v-col>
            <v-col :cols="listItemsCols">
                <ListItems :items="listItems" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import List from "@/components/List";
import ListItems from "@/components/ListItems";
import { mapGetters } from "vuex";

export default {
  name: "Notes",

  components: {
    List,
    ListItems
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters(["user", "list", "listItems"]),
    singleCollection() {
      if (this.list && this.list.length == 1) {
        return true;
      } else {
        return false;
      }
    },
    listItemsCols() {
      if (this.$vuetify.breakpoint.smAndDown || this.singleCollection) {
        return 12;
      } else {
        return 8;
      }
    }
  },

  methods: {},

  mounted() {
    this.$store.dispatch("getList");
  }
};
</script>

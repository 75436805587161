export default {
  snackbar(state) {
    return state.snackbar;
  },
  authReady(state) {
    return state.authReady;
  },
  user(state) {
    return state.user;
  },
  settings(state) {
    return state.settings
  },
  loginDialog(state) {
    return state.loginDialog;
  },
  loginLoading(state) {
    return state.loginLoading;
  },
  list(state) {
    if (state.list) {
      let list = state.list;

      if (list) {
        list = list.sort((a, b) => a.order - b.order);
      }

      return list
    } else {
      return []
    }
  },
  listIndex(state) {
    return state.listIndex
  },
  selectedList(state) {
    if (state.selectedList) {
      return state.selectedList
    } else {
      return {}
    }
  },
  mode(state) {
    return state.mode;
  },
  listItems(state) {
    if (state.listItems) {
      let list = state.listItems;

      if (list) {
        list = list.sort((a, b) => a.order - b.order);
      }

      return list
    } else {
      return []
    }
  },
  listTags(state) {
    if (state.listTags) {
      let list = state.listTags;

      if (list) {
        list = list.sort((a, b) => a.order - b.order);
      }

      return list
    } else {
      return []
    }
  }
};

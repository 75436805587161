<template>
  <v-container class="pt-0">
    <v-row>
      <v-col class="pt-0">
        <v-card flat>
          <v-btn
              v-show="showAdd"
              color="green"
              dark
              small
              absolute
              top
              right
              fab
              @click="addItem()"
              >
              <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-list class="pt-0 pb-10">
            <v-subheader>COLLECTIONS</v-subheader>
            <v-list-item-group v-model="index" color="primary">
                <v-list-item
                v-for="(item, i) in list"
                :key="i"
                @click="listSelected(item)"
                >
                <v-list-item-content>
                    <v-container class="pa-0">
                        <v-row align="center" justify="center">
                            <v-col class="pa-0 pl-3" justify="center">{{ item.title }}</v-col>
                            <v-col v-if="showAdd" cols="2" class="pa-0">
                                <v-btn icon @click="editItem(item)">
                                <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-list-item-content>
                </v-list-item>
            </v-list-item-group>

            <v-divider></v-divider>
            <v-list-item v-if="!user" text @click="login()">
              <v-list-item-content>
                <v-list-item-title>Login</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else text @click="logoff()">
              <v-list-item-content>
                <v-list-item-title>Logoff</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  
    <v-dialog v-model="itemDialog" persistent max-width="400px">
      <v-form ref="form">
        <v-card>
          <v-container fluid class="pt-8 pr-5">
            <v-row>
              <v-col class="pt-0">
                <v-row>
                  <v-col cols="8">
                    <v-text-field label="List Title" v-model="item.title" :rules="inputRules" autofocus></v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-btn icon color="primary" :disabled="saveEnabled" @click="saveItem()">
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="1">
                    <v-btn icon color="primary" :disabled="!item.id" @click="deleteItem(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-btn icon color="primary" @click="close()">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-card class="pa-2" outlined tile>
                  <v-text-field label="Order" v-model="item.order" type="number"></v-text-field>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

export default {
  name: "List",

  data() {
    return {
      item: {},
      index: null,
      originalItem: {},
      itemDialog: false,
      editing: false,
      inputRules: [v => (!!v && v.length > 0) || "Field cannot be empty"]
    };
  },

  computed: {
    ...mapGetters(["list", "selectedList", "listIndex", "user"]),
    showAdd() {
      if (this.user) {
        return this.user.email == "anatoli@amnesia.net";
      } else {
        return false;
      }
    },
    getId() {
      if (this.item) {
        return this.item.id;
      } else {
        return "";
      }
    },
    valid() {
      return (
        this.item != null &&
        this.item.title != undefined &&
        this.item.title != "" &&
        this.item.title.trim() != ""
      );
    },
    dirty() {
      return JSON.stringify(this.item) !== JSON.stringify(this.originalItem);
    },
    saveEnabled() {
      if (!this.valid) {
        return true;
      } else if (this.dirty) {
        return false;
      } else {
        return true;
      }
    }
  },

  methods: {
    listSelected(item) {
      this.$store.dispatch("setSelectedList", item);
      this.$store.dispatch("getListItems", { listID: item.id });

      this.$store.dispatch("saveSettings", {
        key: "list",
        value: item.id
      });
    },
    addItem() {
      this.item = {
        title: "",
        order: null
      };
      this.originalItem = JSON.parse(JSON.stringify(this.item));

      this.itemDialog = true;
    },
    editItem(item) {
      this.item = JSON.parse(JSON.stringify(item));
      this.originalItem = JSON.parse(JSON.stringify(item));

      this.itemDialog = true;
    },
    saveItem() {
      if (!this.item.id) {
        const item = {
          title: this.item.title.trim(),
          order: this.item.order
        };

        if (!item.order || item.order == undefined) {
          item.order = this.getNextOrder();
        }

        this.createItem(item);
      } else {
        const item = {
          id: this.item.id,
          title: this.item.title.trim(),
          order: this.item.order
        };

        this.updateItem(item);
      }

      this.close();
    },
    createItem(item) {
      this.$store.dispatch("createList", item).then(
        response => {
          this.item = response.item;

          const notification = {
            show: true,
            result: true,
            message: response.message
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        error => {
          const notification = {
            show: true,
            result: false,
            message: "Error creating item"
          };

          this.$store.dispatch("showSnackbar", notification);
          console.error("response: ", error);
        }
      );
    },
    updateItem(item) {
      this.$store.dispatch("updateList", item).then(
        response => {
          const notification = {
            show: true,
            result: true,
            message: response.message
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        error => {
          console.error("response: ", error);
        }
      );
    },
    deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.$store.dispatch("deleteList", item).then(
          () => {
            const index = this.list.indexOf(item);
            this.list.splice(index, 1);

            this.itemDialog = false;
          },
          error => {
            console.error("response: ", error);
          }
        );
      }
    },
    close() {
      this.itemDialog = false;
    },
    getNextOrder() {
      if (this.list && this.list.length > 0) {
        return parseInt(this.list[this.list.length - 1].order) + 1;
      } else {
        return 1;
      }
    },
    login() {
      this.$store.dispatch("openLoginDialog", true);
    },
    logoff() {
      this.$store.dispatch("logoff");
      router.push({ name: "Home" }).catch(() => {});
    }
  },

  watch: {
    listIndex: function(val) {
      this.index = val;
    }
  }
};
</script>

import { openDB } from "idb";

// eslint-disable-next-line no-unused-vars
const dbPromise = _ => {
    if (!("indexedDB" in window)) {
        throw new Error("Browser does not support IndexedDB");
    }

    return openDB("NotesDB", 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains("settings")) {
                db.createObjectStore("settings");
            }
        }
    });
};

const get = async (storeName, key) => {
    try {
        const db = await dbPromise();
        const tx = db.transaction(storeName, "readonly");
        const store = tx.objectStore(storeName);

        return store.get(key);
    } catch (error) {
        return error;
    }
};

const save = async (storeName, item) => {
    try {
        const db = await dbPromise();
        const tx = db.transaction(storeName, "readwrite");
        const store = tx.objectStore(storeName);

        store.put(item.value, item.key);

        return tx.complete;
    } catch (error) {
        return error;
    }
};

export default { get, save };